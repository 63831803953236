<template>
  <b-card>
    <b-row v-show="compentences.data.length > 0 || search != null">
      <b-col md="7">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Exibir"
              label-for="h-exibir"
              label-cols-md="4"
              class="text-muted"
            >
              <b-form-select
                v-model="perPage"
                size="sm"
                class="form-content"
                :options="perPageOptions"
                :disabled="isLoading"
              />
            </b-form-group>
          </b-col>
          <div class="col-md">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="search"
                placeholder="Pesquisar"
                debounce="800"
                type="search"
                size="sm"
                :disabled="isLoading"
              />
            </b-input-group>
          </div>
        </b-row>
      </b-col>
      
      <b-col md="2">
        <b-button
          block
          variant="outline-primary"
          size="sm"
          href="/competencia_modelo.xlsx"
          target="_blank"
        >
          Baixar modelo
          <feather-icon icon="DownloadIcon" />
        </b-button>
      </b-col>
      <b-col md="2">
        <b-button
          v-b-modal.modal-import
          block
          variant="relief-primary"
          size="sm"
          @click="addNew"
        >
					Importar Competências
          <feather-icon icon="PaperclipIcon" />
        </b-button>
      </b-col>
    </b-row>
    <div v-show="compentences.data.length > 0 || search != null">
      <b-overlay
        :show="isLoading"
        opacity="0.70"
        variant="transparent"
        rounded="sm"
      >
        <b-table-simple
          id="tableCompetence"
          striped
          responsive
          class="mt-2"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th>Código</b-th>
              <b-th>Tipo</b-th>
              <b-th>Competência</b-th>
              <b-th>Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(tr, indextr) in compentences.data"
              :key="indextr"
            >
              <b-td>
                {{ tr.code }}
              </b-td>
              <b-td>
                {{ tr.type }}
              </b-td>
              <b-td class="text-center">
                {{ tr.competence }}
              </b-td>
              <b-td class="text-center">
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-toggle.sidebar-update-competence
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  @click="updateDetail(tr.id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-modal.modal-delete
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  @click="showDetail(tr.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div
          v-show="totalRows > 10"
          class="row"
        >
          <div class="col-6 col-md-9"> 
            Exibindo {{ perPage }} de {{ totalRows }} registros
          </div>
          <div class="col-6 col-md">
            <b-pagination
              v-model="currentPage"
              class="text-center float-right"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="tableSchools"
              size="sm"
              @change="handlePageChange"
            />
          </div>
        </div>
      </b-overlay>
    </div>
    <div
      v-show="compentences.data.length == 0 && search == null"
      class="text-center"
    >
      <b-overlay
        :show="isLoading"
        opacity="0.70"
        variant="transparent"
        rounded="sm"
      >
        <no-data />
        <b-button
          v-b-modal.modal-import
          block
          variant="relief-primary"
          size="sm"
          @click="addNew"
        >
          Importar Competências
          <feather-icon icon="PackageIcon" />
        </b-button>
      </b-overlay>
    </div>
    <!-- Modal Import -->
    <b-modal
      v-if="competenceImport != null"
      id="modal-import"
      ref="modal-import"  
      ok-variant="primary"
      modal-class="modal-primary"
      centered
      title="Importar"
    >
      <div>
        <b-form method="POST">
          <b-form-input class="hidden" name="_token" :value="csrf" />
          <b-form-group
            label="Arquivo:"
            label-for="i-file"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="arquivo"
              rules="required"
            >
              <b-form-file
                v-model="fileCompetence"
                placeholder="Arquivo"
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                name="file"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </div>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-primary"
            block
            @click="importCompetence()"
          >
            Importar
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <!-- Modal Delete -->
    <b-modal
      v-if="competenceModel != null"
      id="modal-delete"
      ref="modal-delete"  
      ok-variant="danger"
      modal-class="modal-danger"
      centered
      title="Deletar"
    >
      <b-card-text>
        <p>Você está preste a deletar esta competência do sistema: <b>{{ competenceModel.name }}</b></p>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
          @change="newDeleteStatus"
        >
          Concordo em excluir esta competência
        </b-form-checkbox>  
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == false"
            @click="deleteCompetence(competenceModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <!-- Sidebar Update -->
    <b-sidebar
      id="sidebar-update-competence"
      ref="sidebar-update-competence"
      shadow
      right
      bg-variant="white"
      backdrop
      title="Editar habilidade"
    >
      <div>
        <hr>
        <b-form
          enctype="multipart/form-data"
          method="POST"
        >
          <validation-observer ref="simpleRules">
            <b-form-group
              label="CÓDIGO:"
              label-for="i-nome"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="titulo"
                rules="required"
              >
                <b-form-input
                  id="i-nome"
                  v-model="updateAbilities.code"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="TIPO:"
              label-for="i-nome"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="titulo"
                rules="required"
              >
                <b-form-input
                  id="i-nome"
                  v-model="updateAbilities.type"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="COMPETÊNCIA:"
              label-for="i-nome"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="titulo"
                rules="required"
              >
                <b-form-input
                  id="i-nome"
                  v-model="updateAbilities.competence"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-input
              class="hidden"
              name="_token"
              :value="csrf"
            />
            <b-row class="p-1">
              <b-button 
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                class="ml-1 mr-1"
                @click="updateCompetence()"
              >
                Atualizar
              </b-button>
            </b-row>
          </validation-observer>
        </b-form>
      </div>
    </b-sidebar>
  </b-card>
</template>
<script>
  /* eslint-disable */
  import { BCard } from "bootstrap-vue";
  import { mapActions, mapState } from "vuex";
  import Ripple from "vue-ripple-directive";
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {
    required, email, confirmed, password,
  } from '@validations'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import noData from "@/components/systemWarning/NoData.vue";
  
  export default {
    components: {
      BCard,
      ValidationProvider,
      ValidationObserver,
      noData
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        fileCompetence: null,
        competenceImport: 0,

        count: 1,
        search: null,
        perPage: 10,
        currentPage: 1,
        totalRows: null,
        isLoading: true,
  
        DeleteStatus: null,
        competenceModel: null,
  
        updateAbilities: {
          code: null,
          competence: null,
          type: null,
          id: null,
        },
  
        perPageOptions: [
          { value: 10, text: "10" },
          { value: 15, text: "15" },
          { value: 20, text: "20" },
        ],
  
        csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
      };
    },
    computed: {
      ...mapState("compentences", ["compentences"]),
    },
    methods: {
      ...mapActions("compentences", ["update", "import"]),
  
      addNew() {
        this.fileCompetence = null;
        this.competenceImport++;
      },
  
      fetchCompetence(paginated = true, page){
        this.isLoading = true
        this.$store
          .dispatch("compentences/fetchCompentence", {
            paginate: paginated,
            page: page,
            generic: this.search,
            per_page: this.perPage
          })
          .then((response) => {
            this.totalRows = response.total
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            this.isLoading = false
          })
      },

      importCompetence(){
        this.import(this.fileCompetence).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Importado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            }
          })
          this.fileCompetence = null;
          this.$nextTick(() => this.$refs.simpleRulesImport.reset());
        }).finally(() => {
          this.currentPage = 1;
          this.fetchCompetence(true, 1)
          this.$refs['modal-import'].hide()
        })
      },
  
      handlePageChange(value) {
        this.fetchCompetence(true, value)
      },
  
      showDetail(id){
        this.competenceModel = this.$store.getters['compentences/getCompentenceDataById'](id)
      },
  
      newDeleteStatus(value){
        this.DeleteStatus = value
      },
  
      deleteCompetence(id){
        this.isLoading = true;
        let data = id
        this.$store
          .dispatch("compentences/delete", data)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Excluído com sucesso!',
                icon: 'Trash2Icon',
                text: 'Operação executada com sucesso.',
                variant: 'danger'
              }
            })
            this.isLoading = false
            this.DeleteStatus = null
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.currentPage = 1;
            this.fetchCompetence(true, 1)
            this.$refs['modal-delete'].hide()
          })
      },
  
      updateDetail(id){
        this.updateModel = this.$store.getters['compentences/getCompentenceDataById'](id)
        this.updateAbilities.type = this.updateModel.type
        this.updateAbilities.code = this.updateModel.code
        this.updateAbilities.competence = this.updateModel.competence
        this.updateAbilities.id = this.updateModel.id
      },
  
      updateCompetence() {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.update({
              ...this.updateAbilities,
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Atualizado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                },
              })
              this.$nextTick(() => this.$refs.simpleRules.reset());
            })
            .finally(() =>{
              this.$refs['sidebar-update-competence'].hide()
              this.currentPage = 1;
              this.fetchCompetence(true, 1)
            });
          }else{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro de validação!',
                icon: 'AlertTriangleIcon',
                text: 'Por favor preencha todos os campos obrigatorios.',
                variant: 'danger'
              }
            })
          }
        })
          
      }
    },
    created() {
      this.fetchCompetence();
    },
    watch: {
      perPage(){
        this.fetchCompetence()
      },
      search(){
        this.currentPage = 1;
        this.fetchCompetence(true, 1);
      }
    },
  };
  </script>
  <style lang="scss">
    .form-content{
      width: 5rem;
  
    }
    .form-content-search{
      width: 11rem;
    }
  </style>